import React, { useContext } from "react";
import { Card as CardComponent, Text, Message } from "theme-ui";
import { Stack, Main, Sidebar } from "@layout";
import CardList from "@components/CardList";
import Card from "@components/Card";
import Divider from "@components/Divider";
import Seo from "@widgets/Seo";
import { Link, navigate } from "gatsby";
import AuthorExpanded from "@widgets/AuthorExpanded";
import NewsletterCompact from "@widgets/NewsletterCompact";
import { logout, AuthContext } from "@elegantstack/core-blog/src/context/auth";
import LoginCard from "@widgets/LoginCard";
import { Alert, AlertTitle } from "@material-ui/lab";
import Button from "@material-ui/core/Button";

import {
  PostImage,
  PostBody,
  PostComments,
  PostTagsShare
} from "@widgets/Post";

const PostBodyLayout = ({ post, services, location, isUserPermitted }) => (
  <CardComponent variant="paper-lg">
    <PostImage {...post} inCard />
    <PostBody {...post} />
    <PostTagsShare {...post} location={location} />
    {services.disqus && <PostComments {...post} />}
  </CardComponent>
);

const MessagePostNotPermitted = () => {
  return (
    <Alert
      severity="info"
      style={{ marginBottom: 15 }}
      action={
        <Button
          color="inherit"
          size="small"
          onClick={() => {
            navigate("/signup");
          }}
        >
          Login
        </Button>
      }
    >
      <AlertTitle>Just one more step...</AlertTitle>
      Please <Link to={"/signup"}>login</Link> to view the full article.
    </Alert>
  );
};

const Post = ({
  data: { post, tagCategoryPosts, tagPosts, categoryPosts, previous, next },
  location,
  ...props
}) => {
  const relatedPosts = [
    ...(tagCategoryPosts ? tagCategoryPosts.nodes : []),
    ...(tagPosts ? tagPosts.nodes : []),
    ...(categoryPosts ? categoryPosts.nodes : [])
  ];
  const { pageContext: { services = {}, siteUrl } = {} } = props;
  const { user } = useContext(AuthContext);
  const isUserPermitted = user != null || post.featured;

  return (
    <>
      <Seo {...post} siteUrl={siteUrl} />
      <Divider />
      <Stack effectProps={{ effect: "fadeInDown" }}>
        <Main>
          <Card {...post} variant="horizontal-hero" omitExcerpt omitMedia />
        </Main>
        {!user && (
          <Sidebar pl={[null, 2, 4, 5]}>
            <LoginCard />
          </Sidebar>
        )}
      </Stack>
      <Divider space={3} />
      <Stack effectProps={{ fraction: 0 }}>
        <Main>
          {isUserPermitted ? (
            <PostBodyLayout
              post={post}
              location={location}
              services={services}
              isUserPermitted={isUserPermitted}
            />
          ) : (
            user !== undefined && <MessagePostNotPermitted />
          )}
          <Divider />
          <AuthorExpanded author={post.author} />
          <Divider />
          {post.category && (
            <CardList
              title="Related Posts"
              nodes={relatedPosts}
              variant={["horizontal-md"]}
              columns={[1, 2, 2, 2]}
              limit={6}
              distinct
            />
          )}
        </Main>
      </Stack>
    </>
  );
};

export default Post;
